import React from 'react';
import {FaInstagram} from "react-icons/fa6";
import {LiaFacebookSquare} from "react-icons/lia";
import logo from '../assests/logo-bordered.png'
import { RxHamburgerMenu } from "react-icons/rx";
import { Link } from "react-router-dom"

const ScrollNavbar = () => {
    function showNavMenu() {
        const navMenu = document.getElementById('navMenu');
        navMenu
            .classList
            .remove('hidden');
        navMenu
            .classList
            .add('flex');
    }

    return (
        <div
            className={` w-screen bg-[#DE9DAC] items-center py-2 px-2 text-white font-font-playpen text-[2vw] top-0 z-40 fixed overflow-hidden`}>
            <ul
                className='flex items-center space-x-4 justify-evenly max-md:justify-between max-md:px-5'>
                <li><Link to='/' onClick={() => window.scrollTo(0, 0)}><img src={logo} className='w-[50px]'/></Link></li>
                <li
                    className='hover:text-[#702F8A] text-[1.2vw] cursor-pointer font-playpen max-md:hidden'>
                    <Link to='/' onClick={() => window.scrollTo(0, 0)}>Home</Link>
                </li>
                <li
                    className='hover:text-[#702F8A] text-[1.2vw] cursor-pointer font-playpen max-md:hidden'>
                    <a href='#about'>What We Do</a>
                </li>
                <li
                    className='hover:text-[#702F8A] text-[1.2vw] cursor-pointer font-playpen max-md:hidden'>
                    <a href='#Services'>Our Services</a>
                </li>
                <li
                    className='hover:text-[#702F8A] text-[1.2vw] cursor-pointer font-playpen max-md:hidden'>
                    <a href='#Gallery'>Gallery</a>
                </li>
                <li
                    className='hover:text-[#702F8A] text-[1.2vw] cursor-pointer font-playpen max-md:hidden'>
                    <a href='#Testimonials'>Testimonials</a>
                </li>

                <li
                    className='hover:text-[#702F8A] text-[1.2vw] cursor-pointer font-playpen max-md:hidden'>
                    <a href='#Contact'>Contact Us</a>
                </li>
                <div className='flex flex-row items-center justify-between'>
                    <li className='text-white hover:text-[#702F8A] text-[2.3vw] max-md:text-[3em]'><a href='https://www.facebook.com/thewalkingpawsmiami/'><LiaFacebookSquare/></a></li>
                    <li className='text-white hover:text-[#702F8A] text-[2vw] max-md:text-[2.5em]'><a href='https://www.instagram.com/thecaringpaws/'><FaInstagram/></a></li>
                    <li onClick={showNavMenu} className='text-[2.2em] text-white pl-2 md:hidden'><RxHamburgerMenu/></li>
                </div>
            </ul>
        </div>
    );
};

export default ScrollNavbar;
