import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import IMG1 from '../assests/galleryimages/IMG1.jpg';

export default function ContactUs() {
    const form = useRef();

    const [formData, setFormData] = useState({
        from_name: '',
        pet_name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                'service_10txlfk',
                'template_8lhjpsh',
                form.current,
                'lZKbRO7cKCIm4xX2F'
            )
            .then(() => {
                console.log('SUCCESS!');
                alert('Form submitted successfully!');
                setFormData({ from_name: '', pet_name: '', email: '', message: '' });
            }, (error) => {
                console.log('FAILED...', error.text);
                alert('Failed to submit the form.');
            });
    };

    return (
        <section
            id='Contact'
            className='flex items-center justify-center w-full h-auto m-auto mb-4'>
            <form
                ref={form}
                onSubmit={sendEmail}
                className='flex w-[90%] h-full bg-[#70C4E7] flex-col rounded-3xl'
                data-aos='fade-up'>
                <div className='w-full flex flex-row m-1 h-1/2 max-lg:flex-col-reverse'>
                    <div className='w-[50%] h-[90%] flex flex-col max-lg:w-full'>
                        <h3
                            className='font-playpen text-[2em] text-left p-[3vh] text-white max-lg:text-center'>Contact Us</h3>
                        <div className='p-[3vh]'>
                            <h4
                                className='font-playpen text-white text-[2vw] pb-[0.5vh] pl-[1vh] max-lg:text-[1.2em]'>Full Name</h4>
                            <input
                                name='from_name'
                                value={formData.from_name}
                                onChange={handleChange}
                                className='w-[70%] h-[7vh] rounded-3xl p-2 max-lg:w-[90%]'
                                placeholder='Enter Your Full Name'
                                type='text'
                                required="required" />
                        </div>
                        <div className='p-[3vh]'>
                            <h4
                                className='font-playpen text-white text-[2vw] pb-[0.5vh] pl-[1vh] max-lg:text-[1.2em]'>Pet's Name</h4>
                            <input
                                name='pet_name'
                                value={formData.pet_name}
                                onChange={handleChange}
                                className='w-[70%] h-[7vh] rounded-3xl p-2 max-lg:w-[90%]'
                                placeholder="Enter Your Pet's Name"
                                type='text' />
                        </div>
                        <div className='pt-[2vh] pl-[2vh]'>
                            <h4
                                className='font-playpen text-white text-[2vw] pb-[0.5vh] pl-[1vh] max-lg:text-[1.2em]'>Email Address</h4>
                            <input
                                name='email'
                                value={formData.email}
                                onChange={handleChange}
                                className='w-[70%] h-[7vh] rounded-3xl p-2 max-lg:w-[90%]'
                                placeholder='Enter Your Email Address'
                                type='email'
                                required="required" />
                        </div>
                    </div>
                    <div
                        className='w-[50%] h-auto my-[1vh] px-[2vw] flex flex-col items-center justify-center max-lg:w-full'>
                        <img
                            src={IMG1}
                            className='w-[80%] h-[95%] object-cover rounded-3xl border-white border-4'
                            alt='Unable to Display' />
                    </div>
                </div>
                <div className='m-[2vh] w-[85%] h-1/2 flex flex-col px-[0.5vw]'>
                    <h4
                        className='text-white text-[2vw] font-playpen pb-[0.2vh] pl-[0.5vh] max-lg:text-[1.2em]'>Query</h4>
                    <textarea
                        name='message'
                        value={formData.message}
                        onChange={handleChange}
                        className='w-[85vw] h-[30vh] p-2 rounded-xl max-lg:w-[105%]'
                        placeholder='Enter Your Query'
                        required="required" />
                    <div className='flex justify-center items-center pt-[2vh] h-[10%]'>
                        <button
                            type='submit'
                            className='font-playpen text-white text-[1.8vw] max-lg:text-[1.4em] hover:scale-105'>
                            SUBMIT
                        </button>
                    </div>
                </div>
            </form>
        </section>
    );
}
