import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import AboutUs from './myComponents/AboutUs.js';
import Hero from './myComponents/Hero.js';
import Services from './myComponents/Services.js';
import Carousel from './myComponents/Carousel.js';
import ContactUs from './myComponents/ContactUs.js';
import Testimonials from './myComponents/Testimonials.js';
import ScrollNavbar from './myComponents/ScrollNavbar.js';
import MobileNavbar from './myComponents/MobileNavbar.js';
import AboutUsExtended from './myComponents/AboutUsExtended.js';
import ScrollToTop from './myComponents/ScrollTop.js';
import Gimg from './myComponents/Gimg.js';
import Guidelines from './myComponents/Guidelines.js';
import Loader from './myComponents/Loader.js';
import BackgroundMusic from './myComponents/BackgroundMusic.js';
import FootEle from './myComponents/FootEle.js';
import ContactUsExtended from './myComponents/ContactUsExtended.js';

const App = () => {
  const [navbarVisible, setNavbarVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleScroll = () => {
    const heroElement = document.getElementById('hero');
    if (heroElement) {
      const heroBottom = heroElement.getBoundingClientRect().bottom;
      setNavbarVisible(heroBottom <= 0);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      AOS.init({ duration: 1200 });
    }, 1000);
  }, []);

  useEffect(() => {
    if (!loading) {
      AOS.refresh();
    }
  }, [loading]);
  
  

  return (
    <Router>
      <ScrollToTop />
      {loading ? (
        <Loader />
      ) : (
        <div className='w-screen m-0 overflow-x-hidden'>
          <MobileNavbar />
          {navbarVisible && <ScrollNavbar />}
          <BackgroundMusic />
          <Routes>
            <Route 
              path="/"
              element={
                <>
                  <Hero id='hero'/>
                  <AboutUs />
                  <Services />
                  <Carousel />
                  <Testimonials />
                  <ContactUs />
                  <ContactUsExtended />
                  <FootEle />
                </>
              }
            />
            <Route path="/About" element={<AboutUsExtended />} />
            <Route path="/Images" element={<Gimg />} />
            <Route path="/Guidelines" element={<Guidelines />} />
          </Routes>
        </div>
      )}
    </Router>
  );
};

export default App;
