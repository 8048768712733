import React from 'react';
import { IoIosMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";

export default function ContactUsExtended() {
    return (
        <div className='flex items-center w-[100%] min-h-1/2 font-playpen'>
            <ul
                className='flex flex-col w-full h-full items-start justify-center p-10 max-md:p-4'>
                <li className='text-[2.3em] max-md:text-[1.2em] p-2 text-[#582147]'><IoIosMail className='flex-shrink-0'/>
                    <a href='tel:+1 786-602-4224'>+1 786-602-4224</a>
                </li>
                <li className='text-[2.3em] max-md:text-[1.2em] p-2 text-[#DE9DAC]'><FaPhone className='flex-shrink-0'/>
                    <a href='mailto:INFO@THECARINGPAWS.COM'>INFO@THECARINGPAWS.COM</a>
                </li>
                <li className='text-[2.3em] max-md:text-[1.2em] p-2 text-[#70C4E7]'>
                    <FaLocationDot className='flex-shrink-0'/>NORTH MIAMI
                </li>
            </ul>
        </div>
    )
}
