import React from 'react';
import ServiceCard from './ServiceCard';

export default function Services() {
  const services = [
    {
      heading: 'Boarding and Daycare',
      items: ['5-star Holistic Hotel (Boarding)', 'Doggy Play Dates (with pick-up and delivery if needed)'],
    },
    {
      heading: 'Training and Education',
      items: ['Dog Education (Behavioral problems) with a unique method', 'CMT (Canine Mirroring Therapy)'],
    },
    {
      heading: 'Health and Wellness',
      items: ['Non-Anesthesia Teeth Cleaning'],
    },
    {
        heading: 'Additional Services',
        items: ['Recreational Dog Walks ',  'House Visits', 'Uberdog (vet visits or anything you need)'],
    }

  ];

  return (
      <div className='flex flex-wrap justify-center max-md:w-[90%] max-md:flex max-md:flex-col max-md:items-center max-md:justify-center'>
        {services.map((service, index) => {
          const bgColor = index % 2 === 0 ? 'bg-[#DE9DAC]' : 'bg-[#582147]';
          return (
            <ServiceCard
              key={index}
              heading={service.heading}
              items={service.items}
              color={bgColor}
            />
          );
        })}
      </div>
    );
  }
  

