import React from 'react';
import AboutImage from "../assests/AboutImage.jpg";
import {useNavigate} from 'react-router-dom';

export default function AboutUs() {
    const navigate = useNavigate();
    function goTo(){
        navigate("/About");
    }

    return (
        <div id='about' className='flex flex-row w-full h-screen max-lg:h-auto max-lg:flex-col m-auto max-lg:mb-[6vh]'>
            <div
                className='flex items-center justify-center w-1/2 h-full max-lg:w-full max-lg:h-2/5'
                data-aos='fade-right'>
                <img
                    className='object-cover w-full h-full'
                    src={AboutImage}
                    alt='Unable to display'/>
            </div>
            <div
                className="flex flex-col items-center justify-center w-1/2 h-full max-lg:w-full max-lg:my-2 "
                data-aos='fade-up'>
                <div className="flex flex-col items-center justify-center w-full h-full p-4">
                <h3 className='font-playpen font-medium text-center text-[#DE9DAC] mt-10 ml-8 text-[2.5em] max-md:ml-0'>
                        What We Do
                    </h3>
                    <p
                        className="w-4/5 text-left text-[1.4vw] font-playpen max-lg:text-[0.9em] max-lg:p-3">
                        Our mission is to provide a safe and nurturing home environment for dogs, where
                        they can enjoy clean, filtered water, real fresh food, and safe, non-chemical
                        cleaning products. We offer engaging educational play dates that balance freedom
                        and boundaries, all while fostering a strong trust bond between dogs and their
                        caretakers. At our holistic facility, your dog's well-being is our top priority.
                    </p>
                    <button
                        onClick={goTo}
                        className='w-[12vw] mt-4 h-[50px] text-white transition-colors duration-300 ease bg-[#582147] rounded-full text-center text-[1.3vw] px-2 py-2 hover:bg-[#8419cc] active:bg-[#921bc2] font-roboto font-bold max-lg:w-[130px] max-lg:h-[50px] max-lg:text-[1em]'>
                        READ MORE
                    </button>
                </div>
            </div>
        </div>
    );
}
