import React, { useState, useEffect } from 'react';
import IMG1 from '../assests/galleryimages/IMG1.jpg';
import IMG2 from '../assests/galleryimages/IMG2.jpg';
import IMG4 from '../assests/galleryimages/IMG4.jpg';
import IMG8 from '../assests/galleryimages/IMG8.jpeg';
import IMG5 from '../assests/galleryimages/IMG5.jpg';
import IMG6 from '../assests/galleryimages/IMG6.jpg';
import IMG9 from '../assests/galleryimages/IMG9.jpg';
import IMG10 from '../assests/galleryimages/IMG10.jpg';
import IMG11 from '../assests/galleryimages/IMG11.jpg';
import IMG12 from '../assests/galleryimages/IMG12.jpg';
import IMG13 from '../assests/galleryimages/IMG13.jpg';
import { useNavigate } from 'react-router-dom';

const images = [
    IMG1,
    IMG2,
    IMG4,
    IMG5,
    IMG6,
    IMG8,
    IMG9,
    IMG10,
    IMG11,
    IMG12,
    IMG13
];

const Carousel = () => {
    const [currentIndex, setCurrentIndex] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, 3000); // Change slide every 3 seconds

        return () => clearInterval(interval); // Clear interval on component unmount
    }, []);

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const getPrevIndex = () => (currentIndex === 0 ? images.length - 1 : currentIndex - 1);

    const getNextIndex = () => (currentIndex === images.length - 1 ? 0 : currentIndex + 1);

    const navigate = useNavigate();

    function goTo() {
        navigate('/Images');
    }

    return (
        <section id='Gallery' className="relative w-100% h-[70vh] max-lg:h-[60vh] my-[5vw]" data-aos='fade-left'>
            <div className="relative w-full h-full max-sm:h-[80%] overflow-hidden">
                {images.map((image, index) => (
                    <div
                        key={index}
                        className={`w-[50%] absolute inset-0 transition-opacity left-[50%] translate-x-[-50%] rounded-3xl flex items-center justify-center duration-500 border-[#DE9DAC] border-[7px] max-lg:h-[50%] max-lg:top-[25%] ${index === currentIndex ? 'opacity-100 z-20' : 'opacity-0'}`}
                        style={{ transitionDelay: index === currentIndex ? '0s' : '0.5s' }}
                        onClick={goTo}
                    >
                        <img
                            className="object-cover w-full h-full rounded-2xl"
                            src={image}
                            alt={`Slide ${index}`}
                        />
                    </div>
                ))}
                <div className="absolute w-full inset-0 flex items-center">
                    <div className="w-[45%] h-[70%] max-lg:h-[40%] absolute left-[10%] top:50 border-[#70C4E7] border-[7px] rounded-3xl translate-x-[-10%] z-10">
                        <img
                            className="object-cover w-full h-full rounded-2xl"
                            src={images[getPrevIndex()]}
                            alt="Previous Slide"
                        />
                    </div>
                    <div className="w-[45%] h-[70%] max-lg:h-[40%] absolute left-[90%] border-[#582147] border-[7px] translate-x-[-90%] rounded-3xl z-10">
                        <img
                            className="object-cover w-full h-full rounded-2xl"
                            src={images[getNextIndex()]}
                            alt="Next Slide"
                        />
                    </div>
                </div>
            </div>
            <div className='flex flex-row justify-center items-center relative py-[3vh] max-lg:py-[0.2vh]'>
                <button
                    onClick={handlePrevClick}
                    className="px-4 mx-2 py-2 border-none bg-[#582147] text-white cursor-pointer rounded-full transition-colors duration-300 ease hover:bg-purple-950">
                    &#10229;
                </button>
                <button
                    onClick={handleNextClick}
                    className="px-4 py-2 mx-2 border-none bg-[#1c9bd1] text-white cursor-pointer rounded-full transition-colors duration-300 ease hover:bg-blue-700">
                    &#10230;
                </button>
            </div>
        </section>
    );
};

export default Carousel;
