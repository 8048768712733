import React from 'react';
import ServiceList from './ServiceList.js'
import { useNavigate } from 'react-router-dom';

export default function Services() {
  const navigate = useNavigate();
  
  function goTo() {
    navigate('/Guidelines');
  }

  return (
    <div id='Services' className='h-[70%] w-full items-center justify-center px-10 max-md:mt-[20px]' data-aos='fade-down'>
      <div className='max-md:flex max-md:flex-col max-md:items-center max-md:justify-center'>
        <h3 className='font-playpen font-medium text-center text-[#DE9DAC] mt-10 ml-8 text-[2.5em] max-md:ml-0'>Our Services</h3>
        <ServiceList />
      </div>
      <button onClick={goTo}
        className='ml-[3vw] font-playpen text-[#582147] max-md:text-[1.9vw] max-md:mt-[2vh]'>
        Pls Click here for full list of Services and Guidelines
      </button>
    </div>
  )
}
