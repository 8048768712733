import React from 'react';
import { IoPaw } from 'react-icons/io5';
import { IoChevronBackCircleSharp } from 'react-icons/io5';

export default function AboutUsExtended() {
    return (
        <div className="bg-white bg-cover h-auto min-h-full absolute z-50">
            <div className='w-full min-h-screen z-50 px-10 pb-20 xl:px-20 flex flex-col justify-center relative'>
                <button
                    onClick={() => {
                        window.history.back()
                    }}
                    className='text-black mt-10 max-md:mt-5 left-3 text-[4em] max-md:text-[2em]'>  
                    <IoChevronBackCircleSharp />
                </button>
                <h3 className='font-playpen font-bold text-[2em] text-[#DE9DAC] text-center'>
                    What We Do
                </h3>
                <p className='font-playpen font-normal text-black text-[1.1em]'>
                    Welcome to our holistic facility, a haven where your dog's well-being is our top
                    priority. Nestled in a spacious, air-conditioned home with our very own
                    all-fenced dog park, we offer an environment where dogs can thrive. Our mission
                    is to create a safe, warm, and nurturing space for dogs, combining the comforts
                    of a home with professional care.
                </p>

                <h3 className='font-playpen font-semibold text-[#582147] text-[1.3em] pt-5 flex flex-row items-center'>
                    <IoPaw className='m-2 h-6 w-6 flex-shrink-0' />
                    Boarding and Daycare
                </h3>
                <p className='font-playpen font-normal text-black text-[1.1em]'>
                    We provide 5-star holistic boarding services, ensuring that your dog feels at home even when you’re away.
                    Our Doggy Play Dates offer socialization opportunities, complete with pick-up
                    and delivery services for your convenience. For Miami Shores residents, we offer
                    pick-up services for just $5.
                </p>

                <h3 className='font-playpen font-semibold text-[#582147] text-[1.3em] pt-5 flex flex-row items-center'>
                    <IoPaw className='m-2 h-6 w-6 flex-shrink-0' />
                    Training and Education
                </h3>
                <p className='font-playpen font-normal text-black text-[1.1em]'>
                    Understanding that each dog is unique, we offer tailored dog education services to address behavioral
                    problems using a unique method. Our goal is to help you build a strong trust
                    bond with your furry kid, training both dogs and their human companions. Our
                    Canine Mirroring Therapy (CMT) is designed to reflect and correct behaviors,
                    fostering a deeper understanding and connection.
                </p>

                <h3 className='font-playpen font-semibold text-[#582147] text-[1.3em] pt-5 flex flex-row items-center'>
                    <IoPaw className='m-2 h-6 w-6 flex-shrink-0' />
                    Health and Wellness
                </h3>
                <p className='font-playpen font-normal text-black text-[1.1em]'>
                    We are committed to maintaining the highest standards of health and wellness. Our natural, real dog
                    and cat food options, including raw and mild-cooked meals, ensure your pets
                    receive the best nutrition. We use only non-chemical cleaning methods, utilizing
                    organic vinegar and essential oils to create a safe environment. Filtered water
                    is provided to all our guests, promoting hydration and health. Our
                    non-anesthesia teeth cleaning services ensure your pet’s dental hygiene is
                    maintained without the stress of anesthesia.
                </p>

                <h3 className='font-playpen font-semibold text-[#582147] text-[1.3em] pt-5 flex flex-row items-center'>
                    <IoPaw className='m-2 h-6 w-6 flex-shrink-0' />
                    Additional Services
                </h3>
                <p className='font-playpen font-normal text-black text-[1.1em]'>
                    Unique to Miami, we offer recreational dog walks with long leashes and non-restrictive harnesses, allowing
                    your dog to explore and enjoy their surroundings in a structured manner. Our
                    house visits provide personalized care in the comfort of your home.
                    Additionally, our Uberdog service is available for vet visits or any other
                    needs, ensuring your dog is well cared for in all aspects of their life. We have
                    a strict screening process to ensure the safety and compatibility of all our
                    guests. Aggressive or reactive dogs are not accepted, as we strive to maintain a
                    peaceful and harmonious environment. Join us in creating a holistic, nurturing
                    space where dogs can feel safe, loved, and well-cared for, all while fostering
                    strong bonds and promoting overall well-being.
                </p>
            </div>
        </div>
    )
}
