import React from 'react';
import { IoPaw } from 'react-icons/io5';

export default function ServiceCard({ heading, items, color }) {
  return (
    <div className={`relative w-[17vw] min-h-[23vw] max-md:w-[90%] max-md:h-[40vh] flex flex-col items-center justify-start rounded-xl m-[2vw] px-2 ${color}`} data-aos='fade-left'>
      <h1 className='font-playpen font-bold text-white text-[1.5vw] px-3 pt-3 space-x-1 max-md:text-[7vw] max-md:px-5'>{heading}</h1>
      <ul className='list-none mt-2 max-md:mt-[2vh]'>
        {items.map((item, index) => (
         <li key={index} className='flex items-center font-playpen font-medium mb-4 text-white text-[1vw]  max-md:text-[3.5vw] max-md:px-5 space-y-2'>
         <IoPaw className='mr-2 flex-shrink-0 w-[1.2em] h-[1.2em] max-md:w-4 max-md:h-4' />
         {item}
       </li>
        ))}
      </ul>
    </div>
  );
}
