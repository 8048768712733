import React, {useEffect} from 'react';
import '../index.css'; // Ensure the path is correct
import logo from '../assests/image.png'; // Ensure the path is correct
import {FaInstagram} from "react-icons/fa6";
import {LiaFacebookSquare} from "react-icons/lia";
import {Link} from "react-router-dom"

export default function Navbar() {

    return (
        <div>
            <header
                className='absolute w-full bg-transparent mt-2 z-40'
                data-aos='fade-down duration-2000ms ease-in-sine'>
                {/* Ensure the navbar is fixed and transparent */}
                <div
                    className='absolute left-1/2 transform -translate-x-1/2 top-5 max-md:left-3 max-md:translate-x-0 '>
                    <img src={logo} alt='Logo' className='h-[16vh] max-md:h-[10vh] '/>
                </div>

                <ul className='flex justify-between itemns-center mx-5 max-md:hidden font-playpen text-[#DE9DAC]'>
                    <div className='flex mx-4 my-3 justify-evenly w-[40%]'>
                        <li
                            className='text-[2vw] hover:text-[#702F8A] cursor-pointer max-md:hidden'>
                            <Link to='/' onClick={() => window.scrollTo(0, 0)}>Home</Link>
                        </li>
                        <li
                            className='text-[2vw] hover:text-[#702F8A] cursor-pointer max-md:hidden'>
                            <a href='#about'>What We Do</a>
                        </li>
                        <li
                            className='text-[2vw] hover:text-[#702F8A] cursor-pointer max-md:hidden'>
                            <a href='#Services'>Our Services</a>
                        </li>
                    </div>
                    <div
                        className='flex space-x-4 justify-between w-[40%] items-center cursor-pointer max-md:hidden'>
                        <li
                            className='text-[2vw] text-white hover:text-[#ecb7ff] cursor-pointer max-md:hidden'>
                            <a href='#Gallery'>Gallery</a>
                        </li>
                        <li
                            className='text-[2vw] text-white hover:text-[#ecb7ff] cursor-pointer max-md:hidden'>
                            <a href='#Testimonials'>Testimonials</a>
                        </li>
                        <div className='flex justify-evenly items-center max-md:hidden'>
                        <li className='text-white hover:text-[#702F8A] text-[3em] max-md:text-[5em] max-md:hidden'>
                            <a href='https://www.facebook.com/thewalkingpawsmiami/'><LiaFacebookSquare/></a>
                        </li>
                        <li className='text-white hover:text-[#702F8A] text-[2.5em] max-md:text-[5em] max-md:hidden'>
                            <a href='https://www.instagram.com/thecaringpaws/'><FaInstagram/></a>
                        </li>
                        </div>
                    </div>
                </ul>
            </header>

        </div>
    );
}
