import React from 'react';
import { LiaFacebookSquare } from 'react-icons/lia';
import { FaInstagram } from 'react-icons/fa';
import { RxHamburgerMenu } from "react-icons/rx";
import { IoClose } from "react-icons/io5";
import { Link } from 'react-router-dom';

export default function MobileNavbar() {

  function showNavMenu() {
    const navMenu = document.getElementById('navMenu');
    navMenu.classList.remove('hidden');
    navMenu.classList.add('flex');
  }

  function hideNavMenu() {
    const navMenu = document.getElementById('navMenu');
    navMenu.classList.add('hidden');
    navMenu.classList.remove('flex');
  }

  return (
    <div>
      <ul className='flex w-screen top-8 absolute z-40 justify-end items-center pr-6 md:hidden'>
        <li className='text-[2em] text-[white]'><a href='https://www.instagram.com/thecaringpaws/'><FaInstagram/></a></li>
        <li className='text-[2.5em] text-white'><a href='https://www.facebook.com/thewalkingpawsmiami/'><LiaFacebookSquare/></a></li>
        <li onClick={showNavMenu} className='text-[2.2em] text-white pl-2'><RxHamburgerMenu /></li>
      </ul> 

      <ul id='navMenu' className='hidden flex-col w-full h-full fixed bg-[#DE9DAC] opacity-85 top-0 m-0 p-5 z-50 md:hidden justify-center'>
        <div className='w-full items-center absolute top-10'>
          <li onClick={hideNavMenu} className='hover:text-[#702F8A] text-[white] text-[2.7em] cursor-pointer font-playpen p-2 text-center'><IoClose /></li>
        </div>
        <div className='flex flex-col items-start'>
          <li className='hover:text-[#702F8A] text-[white] text-[2.7em] cursor-pointer font-playpen text-left'>
            <Link to='/' onClick={() => window.scrollTo(0, 0)}>Home</Link>
          </li>
          <li className='hover:text-[#702F8A] text-[#ffffff] text-[2.7em] cursor-pointer font-playpen text-left'><a href='#about'>What We Do</a></li>
          <li className='hover:text-[#702F8A] text-[white] text-[2.7em] cursor-pointer font-playpen text-left'><a href='#Services'>Our Services</a></li>
          <li className='hover:text-[#702F8A] text-[white] text-[2.7em] cursor-pointer font-playpen text-left'><a href='#Gallery'>Gallery</a></li>
          <li className='hover:text-[#702F8A] text-[white] text-[2.7em] cursor-pointer font-playpen text-left'><a href='#Testimonials'>Testimonials</a></li>
          <li className='hover:text-[#702F8A] text-[white] text-[2.7em] cursor-pointer font-playpen text-left'><a href='#Contact'>Contact Us</a></li>
          <div className='flex flex-row items-center'>
          <li className='text-white hover:text-[#702F8A] text-[2.3vw] max-md:text-[2.7em]'><a href='https://www.facebook.com/thewalkingpawsmiami/'><LiaFacebookSquare/></a></li>
          <li className='text-white hover:text-[#702F8A] text-[2vw] max-md:text-[2.5em]'><a href='https://www.instagram.com/thecaringpaws/'><FaInstagram/></a></li>
          </div>
        </div>
      </ul>
    </div>
  );
}
