import React, {useState, useEffect} from 'react';
import quotes from '../assests/quotes.png';

const testimonials = [
    {
        text: "Silvia and Kendra run the most caring and personalized doggy boarding i have h" +
                "ad for my 2 little guys ever. It is difficult to find someone reliable i can t" +
                "rust. And I have total peace of mind that they are in good hands when i leave " +
                "them. They are both kind souls who absolutely love pets. They have created a f" +
                "un, safe space for our furry friends. I get nervous leaving my little fragile " +
                "Maltipoos with other dogs, but their screening process ensures that only dogs " +
                "that are friendly and safe are in the group. They also are on hand 24 x 7 so t" +
                "he dogs always have someone close by. Other places just leave the dogs overnig" +
                "ht alone. In addition, they have other important touches, like only feeding th" +
                "e best wholesome nutritious food; filtered water and also a huge back yard whe" +
                "re it is like a fenced in dog park where the dogs are free to run and play.",
        name: "Mark Karaja"
    }, {
        text: "Kendra and her mom are absolutely the best, My dog Rocco absolutely love them." +
                " I am more than grateful for the services they provide and the level of care a" +
                "nd attention they give My dog. Kendra and her mom are so Loving and treat my d" +
                "og like their own. I initially met Kendra through the dog food she sales. Whic" +
                "h it was a life changer to my dogs diet and health. Rocco loves the food and i" +
                " can tell he is more happy and active. The food is real food that does not con" +
                "tain preservatives or any other added chemicals.",
        name: "Cristian Viera"
    }, {
        text: "Kendra and her Mom's house has been my German Shepherd Mollie's second home aw" +
                "ay from home for a few years now. Whenever I need to travel for a few weeks, l" +
                "ong weekend, or even have a last minute meeting that could take all day, Kendr" +
                "a is always ready to help and take good care of my baby girl! You can tell tha" +
                "t they have a passion for dogs - from knowing valuable training tools all the " +
                "way to specific nutritional needs. They truly take care and understand that do" +
                "gs are family and always treat Mollie as if she was one of their own! She is a" +
                " very happy and excited girl when I drop her off and pick her up each time and" +
                " we all know that is a sign of a great boarding/daycare place! :)",
        name: "Maximiliane S."
    }, {
        text: "My puppy almost a year old, was an extremely anxious, yappy, leash puller! I w" +
                "as in desperate need of help, it was evident even in public so a friend recomm" +
                "ended The Caring Paws, best decision ever, with in the week he was walking by " +
                "my side and sitting before entering, comfortable in his cage and much more rel" +
                "axed.The best part is that this is a family owned business, they are so reliab" +
                "le and helpful, and their love for their business and each other is so refresh" +
                "ing, they really do  care, always giving great recommendations and encourageme" +
                "nt.We still have weeks of work left and I m already so relieved with the progr" +
                "ess, I will definitely be continuing my business with them after the training " +
                "is finished with their daycare and boarding programs, I know that they care ab" +
                "out my baby as much as I do !!!",
        name: "Luna"
    }, {
        text: "I rescued a wild puppy from the street of the Dominican Republic, and when I s" +
                "ay wild, she was WILD. A little street dog with no manners, lots of biting and" +
                " no proper socialization with humans, or other animals. Kendra has Gigi in day" +
                " school three days per week, where her mother, Silvia also trains Gigi. I can " +
                "now tell you that Gigi is a totally different dog! When I travel, Gigi stays w" +
                "ith Kendra and Silvia as well and she loves it, in fact when I drop her off fo" +
                "r school. she doesn't event look back at me, or try to leave with me, off she " +
                "goes with Kendra! I highly recommend The Caring paws for all your dogs needs, " +
                "especially training.",
        name: "Krista Karnis"
    }, {
        text: "We left our black lab Ace with Kendra for about 10 days one time and 6 days an" +
                "other, she made sure we were always informed on how he was sending us pictures" +
                " and videos of all the fun he was having with other dogs, during this time we " +
                "also had Silvia train Ace, and after 10 days of being there he came back a new" +
                " dog. When I took him back a second time he was so excited to go back and be t" +
                "here. Silvia and Kendra both really looked out for Ace, I felt they loved him " +
                "as their own dog, and even reached out a few times after to make sure he was o" +
                "k and that we were following the training that was given to him. Highly recomm" +
                "end them.",
        name: "Sean Saylor"
    }, {
        text: "I have a rottweiler that's a little difficult when it comes to socialize with " +
                "other dogs. Martha and Kendra were able to manage his temper and they helped h" +
                "im get along with other dogs something that me his owner never could.  I highl" +
                "y recommend Caring Paws not only because they really care about the well being" +
                " of every dog but because they love animals! They make sure your dog is treate" +
                "d like and individually and rest assure that your dog is in very good hands wi" +
                "th them.",
        name: "Janette Guarrochena"
    }, 
       {
        text: "Our big fur baby Beau loves Kendra and Martha, and so do we! They have been ve" +
                "ry successful in helping us train our puppy and always take the best of care o" +
                "f him when 'babysitting'. They are reliable and professional, and our Beau is " +
                "always happy to see them. Thank you for everything you do for us. We love you!" +
                " We could not have done this without you!",
        name: "Janice Graham"
    }, {
        text: "Excellent service, very caring and extremely attentive! Simply the BEST! Our d" +
                "og stays with them when we travel and he is always very happy there! We are ve" +
                "ry grateful!!!",
        name: "Camila Gonzalez"
    }, {
        text: "Kendra and her mom have been so wonderful with our dogs. I can always count on" +
                " them to show up when scheduled and Kendra has been so forgiving and flexible " +
                "with us, particularly during the pandemic. I know our pups love Kendra and her" +
                " mom because they get so excited when they see her, as we often see them out w" +
                "alking other dogs in our neighborhood. They have also been great with putting " +
                "us in touch with other services like teeth cleaning and training, which has be" +
                "en so helpful. I definitely recommend The Caring Paws without hesitation.",
        name: "Dalin Holyoak"
    }, {
        text: "I was fortunate to find Kendra for my puppy as I transitioned from working at " +
                "home to going back to the office. She understands dogs and he is always happy " +
                "when he sees her which is a comforting sign. He comes back tired, as I know he" +
                " plays all day. What I like about Kendra is that her doggy daycare is small en" +
                "ough to receive personal attention and I never feel as though he may get injur" +
                "ed by having too many dogs running around. She offers training and food advice" +
                " and he has continued to develop his socializing skills while there.",
        name: "Glen Turf"
    }, {
        text: "I have 3 dogs that every time I travel I leave them with The Caring Paws, my d" +
                "ogs get all excited before arriving! Its like they prefer staying there than w" +
                "ith me! Kendra always worries and takes care about. my babies, she has gone ab" +
                "ove and beyond to even take them to the vet when I wasn't around! They are ama" +
                "zing and really take care like they are their own babies! I couldn't recommend" +
                " them more!",
        name: "Rodrigo Violante"
    }, 

];

const Testimonials = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState(true);

    const nextTestimonial = () => {
        setFade(false);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
            setFade(true);
        }, 500); // duration matches the CSS transition duration
    };

    const prevTestimonial = () => {
        setFade(false);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
            setFade(true);
        }, 500); // duration matches the CSS transition duration
    };

    useEffect(() => {
        const intervalId = setInterval(nextTestimonial, 10000); // Change slide every 5 seconds
        return () => clearInterval(intervalId); // Clean up when component unmounts
    }, []);

    return (
        <section id='Testimonials' className='w-full h-auto flex flex-col items-center m-auto justify-center max-lg:mt-[2vh] mb-[5vh] relative' data-aos='fade-up'>
            <div className='flex flex-col items-center justify-center h-[15%] mt-5'>
            <h3 className='font-playpen font-medium text-center text-[#DE9DAC] mt-10 ml-8 text-[2.5em] max-md:ml-0'>Testimonials</h3>
            </div>
            <div className="my-0 w-full min-h-[100%]">
                <div
                    className={`mx-auto text-center relative w-[80%] text-[1.318em] max-sm:text-[0.9em] mx-lg font-playpen lg:p-12 p-8 lg:w-[70%] my-5 border-4 border-[#70C4E7] flex flex-col items-center justify-center rounded-3xl bg-[#FFECFA] md:py-8`}>
                    <img
                        src={quotes}
                        className={`left-[0%] translate-x-[50%] top-[0%] translate-y-[-50%] absolute lg:w-[12vh] w-[10vw]`} /> 
                    <div className={`transition-opacity duration-500 ease-in-out ${fade ? 'opacity-100' : 'opacity-0'}`}>
                        {testimonials[currentIndex].text}
                    </div>
                    <div className={`mt-3 text-base font-playpen font-bold text-[#582147] transition-opacity duration-500 ease-in-out ${fade ? 'opacity-100' : 'opacity-0'}`}>
                        - {testimonials[currentIndex].name}
                    </div>
                </div>
                <div className="flex justify-center gap-4">
                    <button
                        onClick={prevTestimonial}
                        className="px-4 py-2 border-none bg-[#582147] text-white cursor-pointer rounded-full transition-colors duration-300 ease hover:bg-purple-950">
                        &#10229;
                    </button>
                    <button
                        onClick={nextTestimonial}
                        className="px-4 py-2 border-none bg-[#1c9bd1] text-white cursor-pointer rounded-full transition-colors duration-300 ease hover:bg-blue-700">
                        &#10230;
                    </button>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
