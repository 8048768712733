import React, { useState, useRef } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import Sound from '../assests/background.mp3';
import { BsMusicNote } from 'react-icons/bs';
import { MdMusicOff } from 'react-icons/md';

export default function BackgroundMusic() {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const handleToggle = () => {
    if (isPlaying) {
      audioRef.current.audioEl.current.pause();
    } else {
      audioRef.current.audioEl.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div>
      <button
        className='fixed right-[5%] z-[70] top-[40%] translate-y-[50%] text-white text-[1.8em] flex items-center justify-center w-10 py-[6px] rounded-full bg-[#DE9DAC]'
        onClick={handleToggle}
      >
        {isPlaying ? <MdMusicOff /> : <BsMusicNote />}
      </button>
      <ReactAudioPlayer
        ref={audioRef}
        src={Sound}
        autoPlay={false}
        loop
        volume={0.8}
      />
    </div>
  );
}
