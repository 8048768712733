import React from 'react'
import ClipLoader from "react-spinners/ClipLoader";
import { LuDog } from "react-icons/lu";

export default function Loader() {
  return (
    <div className='w-full h-full bg-white absolute flex items-center justify-center'>
      <ClipLoader color='#DE9DAC' size={70} className='h-autorelative'/>
      <LuDog className='w-10 h-auto text-[#582147] absolute'/>
      <p className='absolute md:bottom-20 bottom-32 font-playpen text-[0.7em] text-[#DE9DAC]'>Your Dog's Home Away from Home</p>
    </div>
  )
}
