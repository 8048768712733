import React from 'react';

export default function FootEle() {
  return (
    <div>
      <div className='flex max-md:flex-col justify-between md:px-10 py-3 items-center bg-[#582147] w-full min-h-[10vh] bottom-0'>
        <h4 className='text-[0.7em] text-white font-playpen'>The Caring Paws &#169;</h4>
        <h4 className='text-[0.7em] text-white font-playpen'>Website By Parikalpana.io</h4>
      </div>
    </div>
  )
}
