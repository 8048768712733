import {React, useState} from 'react';
import IMG1 from '../assests/galleryimages/IMG1.jpg';
import IMG2 from '../assests/galleryimages/IMG2.jpg';
import IMG4 from '../assests/galleryimages/IMG4.jpg';
import IMG8 from '../assests/galleryimages/IMG8.jpeg';
import IMG5 from '../assests/galleryimages/IMG5.jpg';
import IMG6 from '../assests/galleryimages/IMG6.jpg';
import IMG9 from '../assests/galleryimages/IMG9.jpg';
import IMG10 from '../assests/galleryimages/IMG10.jpg';
import IMG11 from '../assests/galleryimages/IMG11.jpg';
import IMG12 from '../assests/galleryimages/IMG12.jpg';
import IMG13 from '../assests/galleryimages/IMG13.jpg';
import {IoChevronBackCircleSharp} from "react-icons/io5";


const images = [
    IMG1,
    IMG2,
    IMG4,
    IMG5,
    IMG6,
    IMG8,    
    IMG9,
    IMG10,
    IMG11,
    IMG12,
    IMG13
];


export default function Gimg() {
    const [zoomedImage, setZoomedImage] = useState(null);
  
    const handleImageClick = (image) => {
      setZoomedImage(image);
    };
  
    const handleBackButtonClick = () => {
      setZoomedImage(null);
    };
  
    return (
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 mt-4 p-4 absolute z-50">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative cursor-pointer"
            onClick={() => handleImageClick(image)}
          >
            <img
              src={image}
              alt={`Gallery Image ${index + 1}`}
              className="w-full h-auto object-cover"
            />
          </div>
        ))}
        {zoomedImage && (
          <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-75">
            <div className="max-w-screen-lg max-h-screen flex items-center justify-center">
              <img
                src={zoomedImage}
                alt="Zoomed Image"
                className="w-[90%] h-auto object-contain overflow-scroll"
              />
            </div>
            <button
              onClick={handleBackButtonClick}
              className="absolute bottom-7 left-8 text-white"
            >
              Back
            </button>
          </div>
        )}
        <button
                    onClick={() => {
                        window
                            .history
                            .back()
                    }
}
                    className='text-pink-400 bottom-1 right-3 text-[1.3em] absolute z-[70]'>Back</button>
      </div>
    );
  }