import React from 'react';
import { IoPaw } from 'react-icons/io5';
import { IoChevronBackCircleSharp } from 'react-icons/io5';

export default function Guidelines() {
  return (
    <div className='top-0 absolute z-50 w-screen bg-white md:p-20 p-5 flex flex-col items-center font-playpen'>
      <div>
        <button
          onClick={() => {
            window.history.back();
          }}
          className='text-[#DE9DAC] mt-0 left-3 text-[4em]'
        >
          <IoChevronBackCircleSharp />
        </button>
        <h3 className='text-center font-playpen p-5 text-[3em] text-[#DE9DAC]'>
          Facility Features
        </h3>
        <ul>
          <li className='flex flex-row justify-start items-center text-black text-[0.9em] font-playpen'>
            <IoPaw className='m-1 h-6 w-6 flex-shrink-0' />
            <span className='ml-2'>Our own all-fenced dog park (as seen in homepage video)</span>
          </li>
          <li className='flex flex-row justify-start items-center text-black text-[0.9em] font-playpen'>
            <IoPaw className='m-1 h-6 w-6 flex-shrink-0' />
            <span className='ml-2'>A big house with different spaces, all with A/C</span>
          </li>
        </ul>
        <h3 className='text-center font-playpen p-5 text-[3em] text-[#DE9DAC]'>
          Guidelines
        </h3>
        <ul>
          <li className='flex flex-row justify-start items-center text-black text-[0.9em] max-md:text-[0.6em] font-playpen'>
            <IoPaw className='m-1 h-6 w-6 flex-shrink-0' />
            <span className='ml-2'>We don't require your pup to be spayed or neutered if they are not aggressive.</span>
          </li>
          <li className='flex flex-row justify-start items-center text-black text-[0.9em] max-md:text-[0.6em] font-playpen'>
            <IoPaw className='m-1 h-6 w-6 flex-shrink-0' />
            <span className='ml-2'>We don't need your dog to be over vaccinated.</span>
          </li>
          <li className='flex flex-row justify-start items-center text-black text-[0.9em] max-md:text-[0.6em] font-playpen'>
            <IoPaw className='m-1 h-6 w-6 flex-shrink-0' />
            <span className='ml-2'>We use non-chemical cleaning methods (organic vinegar and essential oils).</span>
          </li>
          <li className='flex flex-row justify-start items-center text-black text-[0.9em] max-md:text-[0.6em] font-playpen'>
            <IoPaw className='m-1 h-6 w-6 flex-shrink-0' />
            <span className='ml-2'>We provide filtered water.</span>
          </li>
          <li className='flex flex-row justify-start items-center text-black text-[0.9em] max-md:text-[0.6em] font-playpen'>
            <IoPaw className='m-1 h-6 w-6 flex-shrink-0' />
            <span className='ml-2'>We care about nutrition.</span>
          </li>
          <li className='flex flex-row justify-start items-center text-black text-[0.9em] max-md:text-[0.6em] font-playpen'>
            <IoPaw className='m-1 h-6 w-6 flex-shrink-0' />
            <span className='ml-2'>We have a very strict screening process, so we don’t accept any aggressive or reactive dogs.</span>
          </li>
        </ul>
        <h3 className='text-center font-playpen p-5 text-[3em] text-[#DE9DAC]'>
          Services
        </h3>
        <ul>
          <li className='flex flex-row justify-start items-center text-black text-[0.9em] font-playpen'>
            <IoPaw className='m-1 h-6 w-6 flex-shrink-0' />
            <span className='ml-2'>Doggy Play Dates (with pick-up and delivery if needed, $5 pick-up in Miami Shores)</span>
          </li>
          <li className='flex flex-row justify-start items-center text-black text-[0.9em] font-playpen'>
            <IoPaw className='m-1 h-6 w-6 flex-shrink-0' />
            <span className='ml-2'>5-star Holistic Hotel (Boarding)</span>
          </li>
          <li className='flex flex-row justify-start items-center text-black text-[0.9em] font-playpen'>
            <IoPaw className='m-1 h-6 w-6 flex-shrink-0' />
            <span className='ml-2'>Dog Education (Behavioral problems) with a unique method</span>
          </li>
          <li className='flex flex-row justify-start items-center text-black text-[0.9em] font-playpen'>
            <IoPaw className='m-1 h-6 w-6 flex-shrink-0' />
            <span className='ml-2'>Building a strong trust bond with your furry kid (training humans too)</span>
          </li>
          <li className='flex flex-row justify-start items-center text-black text-[0.9em] font-playpen'>
            <IoPaw className='m-1 h-6 w-6 flex-shrink-0' />
            <span className='ml-2'>CMT (Canine Mirroring Therapy)</span>
          </li>
          <li className='flex flex-row justify-start items-center text-black text-[0.9em] font-playpen'>
            <IoPaw className='m-1 h-6 w-6 flex-shrink-0' />
            <span className='ml-2'>Recreational Dog Walks (unique in Miami)</span>
          </li>
          <li className='flex flex-row justify-start items-center text-black text-[0.9em] font-playpen'>
            <IoPaw className='m-1 h-6 w-6 flex-shrink-0' />
            <span className='ml-2'>House Visits</span>
          </li>
          <li className='flex flex-row justify-start items-center text-black text-[0.9em] font-playpen'>
            <IoPaw className='m-1 h-6 w-6 flex-shrink-0' />
            <span className='ml-2'>Natural Real Dog & Cat Food (Raw and Mild cooked)</span>
          </li>
          <li className='flex flex-row justify-start items-center text-black text-[0.9em] font-playpen'>
            <IoPaw className='m-1 h-6 w-6 flex-shrink-0' />
            <span className='ml-2'>Non-Anesthesia Teeth Cleaning</span>
          </li>
          <li className='flex flex-row justify-start items-center text-black text-[0.9em] font-playpen'>
            <IoPaw className='m-1 h-6 w-6 flex-shrink-0' />
            <span className='ml-2'>Uberdog (vet visits or anything you need)</span>
          </li>
        </ul>
      </div>
    </div>
  );
}
