import React, { useEffect, useRef, useState } from 'react';
import Navbar from './Navbar.js';
import home_vid from '../assests/HomeVid.mp4';
import review from '../assests/review.png';
import ClipLoader from "react-spinners/ClipLoader";
import { LuDog } from "react-icons/lu";

export default function Hero() {
  const [videoLoading, setVideoLoading] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    const handleCanPlayThrough = () => {
      setVideoLoading(false);
    };

    if (video) {
      video.addEventListener('canplaythrough', handleCanPlayThrough);
    }

    return () => {
      if (video) {
        video.removeEventListener('canplaythrough', handleCanPlayThrough);
      }
    };
  }, []);


  return (
    <div id='hero' data-aos='fade-down' className='w-screen top-0'>
      <Navbar />
      <div id='Home' className="h-[92vh] w-100% max-md:h-full relative">
        
        <div className="w-full h-full flex bg-[#FFEBF9] max-md:flex max-md:flex-col-reverse max-md:mt-[-5px]">
          <div className="w-1/2 h-full flex flex-col items-left justify-center max-md:w-full max-md:h-[30%] max-md:items-center">
            <h3 className="text-[#DE9DAC] text-[4.5vw] font-playpen font-medium mt-[5vw] mx-10 w-[90%] text-left max-md:text-[1.8em]">
              Your Dog’s Home Away From Home
            </h3>
            <h1 className='font-playpen text-[2.5vw] mx-10 text-left w-[90%] max-md:text-[1.1em]'>
              Best care for your dog in<br />Miami, Fl
            </h1>
            <a href='#Contact' className='w-[160px] h-[50px] text-white transition-colors duration-300 ease bg-[#582147] rounded-full text-center text-[1.3vw] md:ml-10 py-2  font-playpen font-bold hover:bg-[#9e14b9] active:bg-[#330969] max-md:w-[140px] max-md:h-[50px] max-md:text-[1em] max-md:mb-5 max-md:mt-[-3px] my-3 flex items-center justify-center'>
              CONTACT US
            </a>
          </div>
          <div className="w-1/2 h-full md:relative max-md:w-full max-md:h-[70%]">
          {videoLoading && (
          <div className="absolute inset-0 w-full h-full bg-white flex items-center justify-center z-10">
            <ClipLoader color='#DE9DAC' size={70} className='h-auto'/>
            <LuDog className='w-10 h-auto text-[#582147] absolute'/>
          </div>
        )}
            <div className="md:absolute inset-0 bg-[#000] opacity-40 filter blur-[30px]"></div>
            <video
              ref={videoRef}
              className="md:absolute inset-0 w-full h-full object-cover"
              autoPlay
              loop
              muted
            >
              <source src={home_vid} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div className='flex flex-row justify-center items-center w-screen h-[8vh] bg-[#DE9DAC] max-md:flex-col'>
        <div>
          <img className='w-[36vh] h-auto px-5 max-md:w-[25vh]' src={review} alt='ratings' />
        </div>
      </div>
    </div>
  );
}
